<template>
  <BasicLayout />
</template>

<script>
import BasicLayout from './layout/basic-layout.vue'
export default {
  name: 'App',
  components: {
    BasicLayout
  },
  created() {
    console.log('app.vue created')

    // 首次进入，先调一次埋点
    setTimeout(() => {
      // let path = location.href
      // window._hmt.push(['_trackEvent', 'link-register', 'click', path, 1]);
    }, 500)
  },
  mounted() {
    console.log('app.vue mounted')
    // 爱番番
    // var _hmt = _hmt || [];
    // (function () {
    //   var hm = document.createElement("script");
    //   hm.src = "https://hm.baidu.com/hm.js?63bef3a5c9c74dc8d9d3ed3ae89fe7a3";
    //   var s = document.getElementsByTagName("script")[0];
    //   s.parentNode.insertBefore(hm, s);
    // })();
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?55a31ec395f831d268e7c33e66db43e2";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }
}
</script>

<style lang="scss">
@import './static/style/common.scss';
@import './static/style/init.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
